import GlobalStyles from "./styles/global";
import Home from "./Pages";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {PICTURES_DATA} from "./entities/pictures";
import Id from "./Pages/buy/[id]";

function App() {
  return (
    <>
      <GlobalStyles/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path={"buy"}>
            {PICTURES_DATA.map((el, index)=>(
              <Route key={index} path={`${index}`} element={<Id id={index} />} />
            ))}
          </Route>


        </Routes>
      </BrowserRouter>


    </>
  );
}

export default App;
