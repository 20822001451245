import { css } from "styled-components";

export const Fonts = css`
  @font-face {
    font-family: "Gella Display";
    src: url("/fonts/GellaDisplay-ExpandedMedium.otf");
  }
  @font-face {
    font-family: "Involve";
    src: url("/fonts/Involve-VF.ttf");
  }
`;
